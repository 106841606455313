<template>
  <div id="app">
    <Nav />
    <div class="mt-50">
      <img class="top-img" :src="require(`@/assets/banner/banner-06${$i18n.locale == 'zh'?'z':'e'}.png`)" alt="" />
      <div class="record">
        <div class="record-bac"></div>
        <!-- 大事记 -->
        <div class="record-li flex between">
          <div class="record-li-left">
            <div class="record-left-img1" 
              v-for="item in journalListL" :key="item.id"
              :style="{
                        backgroundImage:
                          'url(' +
                          require('@/assets/rec/dsj-' +
                          item.year +
                          '.png') +
                          ')',
                    }"
                    @click="incident(item)"
                    >
                <div class="date-sj" :class="item.id == listIndex?'rotating-element' : ''"></div>
            </div>
          </div>
          <div class="record-li-right">
            <div class="mb-26" v-for="(item,index) in list" :key="index">
                <div class="item-right-top" v-if="item.url">
                    <div class="bg-right"></div>
                    <img  class="bg-img" :src=" require('@/assets/rec/' +item.url )" alt="">
                    <div class="en-img"></div>
                </div>
                <div class="name"  v-if="$i18n.locale == 'zh'">{{ item.title }}</div>
                <div class="name"  v-else>{{ item.titleEn }}</div>
                <div class="text"  v-if="$i18n.locale == 'zh'">{{ item.name }}</div>
                <div class="text"  v-else>{{ item.nameEn }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<style scoped></style>
<script>
import Nav from "@/components/nav.vue";
import Footer from "@/components/footer.vue";
export default {
  name: "App",
  components: {
    Nav,
    Footer,
  },
  data() {
    return {
      bigevent:[],
      list: [],
        listIndex:0,
        journalListL:[
          {
            id:0,
            year:'2024',
            child:[
              {
                url:'rec-7.png',
                title:'2024年11月1日',
                titleEn:'November 1, 2024',
                name:'“征程·光明”音乐会亮相文旅部第23届“上海国际艺术节”',
                nameEn:'The concert “Journey · Light” performed at the 23rd Shanghai International Arts Festival organized by the Ministry of Culture and Tourism'
              },
              {
                title:'2024年10月19日',
                titleEn:'October 19, 2024',
                name:'民族交响套曲《江河湖海颂》亮相2024紫金文化艺术节庆祝中华人民共和国成立75周年优秀舞台艺术作品展演',
                nameEn:'The Chinese symphonic suite “Ode to Rivers, Lakes, and Seas” featured in the showcase of outstanding stage art works at the 2024 Zijin Culture and Arts Festival celebrating the 75th anniversary of the founding of the People’s Republic of China'
              },
              {
                title:'2024年9月26日',
                titleEn:'September 26, 2024',
                name:'“光明行”音乐会入选中国音协深圳“一带一路”国际音乐节',
                nameEn:'The concert “Journey · Light” selected for “Belt and Road” International Music Festival in Shenzhen organized by China Musicians Association'
              },
              {
                title:'2024年9月13日—22日',
                titleEn:'September 13-22, 2024',
                name:'举办第四届“运河情·江南韵”——大运河民族音乐文化传承展示周',
                nameEn:'Held the fourth “Affection for the Canal: Melodies of Jiangnan” Grand Canal Chinese Music Heritage Week'
              },
              {
                title:'2024年8月6日',
                titleEn:'August 6, 2024',
                name:'承办中国音协“2024中国江苏二胡之乡民族音乐盛典·苏州分会场系列活动——中国音乐金钟奖‘金钟之星’古筝名家名曲《虎丘情韵》音乐会”',
                nameEn:'Hosted “the Charm of Tiger Hill”, a concert of guzheng masters and masterpieces for China Music Golden Bell Award "Star of Golden Bell" as part of the “2024 China Jiangsu Home-of-Erhu National Music Festival” Suzhou branch venue event series organized by China Musicians Association'
              },
              {
                title:'2024年7月',
                titleEn:'July 2024',
                name:'委约作品《江河湖海颂》入选2022—2023年度“时代交响”创作扶持计划重点扶持作品',
                nameEn:'Commissioned work “Ode to Rivers, Lakes, and Seas” selected as a key project for the 2022–2023 "Symphony of the Era - China Symphonic Music Composition Support Program"'
              },
              {
                title:'2024年6月',
                titleEn:'June 2024',
                name:'委约作品《征程·光明》入选文旅部2024—2025年度“时代交响”创作扶持计划扶持作品',
                nameEn:'Commissioned work “Journey · Light” selected for the “Symphony of the Era - China Symphonic Music Composition Support Program" by the Ministry of Culture and Tourism'
              },
              {
                title:'2024年4月19日',
                titleEn:'April 19, 2024',
                name:'“复兴”民族交响音乐会亮相第二届“国乐之春”音乐节，国家大剧院',
                nameEn:'Chinese symphony concert “Rejuvenation” performed at the National Center for the Performing Arts as part of the 2nd “Spring of Chinese Music” Festival'
              },
              {
                title:'2024年1月4日—7日',
                titleEn:'January 4-7, 2024',
                name:'受江苏省文旅厅、中国驻名古屋总领事馆邀请，赴日本名古屋参加“欢乐春节·水韵江苏”文化交流演出',
                nameEn:'Performed in Nagoya, Japan at the invitation of the Jiangsu Provincial Department of Culture and Tourism and the Chinese Consulate-General in Nagoya as part of the “Happy Chinese New Year · Water Charm of Jiangsu” cultural exchange event'
              },
            ]
          },
          {
            id:1,
            year:'2023',
            child:[
              {
                title:'2023年12月23日—2024年1月1日',
                titleEn:'December 23, 2023 to January 1, 2024',
                name:'承办“2024新年民族音乐会暨第四届江苏•苏州民族音乐展演”',
                nameEn:'Hosted the “2024 New Year Chinese Music Concert” and “4th Jiangsu-Suzhou Chinese Music Festival”'
              },
              {
                title:'2023年11月4日',
                titleEn:'November 4, 2023',
                name:'“江南”音乐会亮相文旅部第22届“上海国际艺术节”',
                nameEn:'The concert “Jiangnan” featured at the 22nd Shanghai International Arts Festival organized by the Ministry of Culture and Tourism'
              },
              {
                title:'2023年10月',
                titleEn:'October 2023',
                name:'民族交响乐《征程·光明》亮相2023紫金文化艺术节，获“优秀大型音乐作品奖”。',
                nameEn:'Chinese Symphony “Journey · Light” showcased at the 2023 Zijin Culture and Arts Festival, winning the " Outstanding Grand-Scale Music Work Award"'
              },
              {
                title:'2023年9月21日—10月8日',
                titleEn:'September 21 to October 8, 2023',
                name:'第二次欧洲巡演——赴德国、奥地利、斯洛文尼亚等3国7城市举办7场“丝竹里的江南”大型民族管弦乐音乐会',
                nameEn:'Second European tour with seven “Jiangnan Within Sizhu” grand-scale Chinese orchestral concerts across seven cities in Germany, Austria, and Slovenia'
              },
              {
                title:'2023年9月',
                titleEn:'September 2023',
                name:'民族交响乐《征程·光明》入选江苏省文旅厅2023年江苏省舞台艺术精品创作扶持工程重点投入项目',
                nameEn:'Chinese Symphony “Journey · Light” selected as a key project in the Jiangsu Provincial Support Program for the 2023 Creation of Quality Stage Art Works by the Jiangsu Provincial Department of Culture and Tourism'
              },
              {
                title:'2023年9月20日—9月27日',
                titleEn:'September 20 to September 27, 2023',
                name:'举办第三届“运河情·江南韵”——大运河民族音乐文化传承展示周',
                nameEn:'Held the third “Affection for the Canal: Melodies of Jiangnan” Grand Canal Chinese Music Heritage Week'
              },
              {
                title:'2023年9月',
                titleEn:'September 2023',
                name:'“运河情·江南韵”——大运河民族音乐文化传承展示周入选文旅部服务司、中国文化群众文化学会首届中国群众文化品牌发展大会“全国优秀群众文化品牌案例 ”',
                nameEn:'“Affection for the Canal: Melodies of Jiangnan” Grand Canal Chinese Music Heritage Week recognized as a " national outstanding case of mass culture brands" at the first China Mass Cultural Brand Development Conference organized by the Department of Public Services of the Ministry of Culture and Tourism and China Mass Culture Association'
              },
              {
                title:'2023年8月23日—9月2日',
                titleEn:'August 23 to September 2, 2023',
                name:'承办中国音协“丝路花语”中国民族音乐巡演活动，赴印度尼西亚、孟加拉国、尼泊尔开展4场巡演',
                nameEn:'Hosted the “The Floral Silk Road” Chinese Music Tour organized by China Musicians Association, with four concerts in Indonesia, Bangladesh, and Nepal'
              },
              {
                title:'2023年6月',
                titleEn:'June 2023',
                name:'“丝竹里的江南”入选文旅部“全国民族器乐展演”，获评优秀乐团',
                nameEn:'“Jiangnan Within Sizhu” selected for the "National Traditional Instrumental Music Showcase" organized by the Ministry of Culture and Tourism and the Orchestra recognized as an outstanding orchestra'
              },
              {
                title:'2023年4月25日',
                titleEn:'April 25, 2023',
                name:'承办中国音协“弓弦华章”——中国音协民族弓弦乐学会成立暨名家名曲音乐会',
                nameEn:'Hosted the “Brilliant Music on the Bow and Strings” event, a concert featuring masters and masterpieces in celebration of the establishment of the Chinese Bow-String Instrumentalist Society affiliated to China Musicians Association'
              },
              {
                title:'2023年4月',
                titleEn:'April 2023',
                name:'“江河湖海颂”“风雅颂之交响”入选中宣部、文旅部、中国音协“时代交响”——全国优秀乐团邀请展演',
                nameEn:'“Ode to Rivers, Lakes, and Seas” and “The Feng-Ya-Song Symphony” selected for the “Symphonies of the Era - Invitational Concerts by National Outstanding Orchestras” organized by the Publicity Department of the CPC Central Committee, the Ministry of Culture and Tourism, and China Musicians Association'
              },
              {
                title:'2023年3月',
                titleEn:'March 2023',
                name:'委约作品《征程·光明》入选2023国家艺术基金舞台艺术创作资助项目',
                nameEn:'Commissioned work “Journey · Light” selected for the 2024 National Art Foundation Subsidized Project'
              },
              {
                title:'2023年1月',
                titleEn:'January 2023',
                name:'获评江苏省文旅厅“江苏省国有文艺院团一级院团”',
                nameEn:'Recognized as a “First-Class State-Owned Performing Arts Troupe” by the Jiangsu Provincial Department of Culture and Tourism'
              },
            ]
          },
          {
            id:2,
            year:'2022',
            child:[
              {
                title:'2022年12月28日—31日',
                titleEn:'December 28-31, 2022',
                name:'承办“第三届江苏·苏州民族音乐展演暨2023新年民族音乐会”',
                nameEn:'Hosted “The 3rd Jiangsu-Suzhou Chinese Music Festival” and “2023 New Year Chinese Music Concert”'
              },
              {
                title:'2022年11月14日',
                titleEn:'November 14, 2022',
                name:'“吴越春秋”交响音乐会入选文旅部“礼赞新时代 奋进新征程”演艺大世界·全国优秀舞台艺术作品展演',
                nameEn:"Symphonic concert “The Wu Yue Eras” selected for the Ministry of Culture and Tourism's “Praise for the New Era, Embarking on a New Journey - National Showcase of Outstanding Stage Art Works”"
              },
              {
                title:'2022年11月7日',
                titleEn:'November 7, 2022',
                name:'中国民族交响组曲“江南”亮相2022紫金文化艺术节',
                nameEn:'Chinese Symphonic Suite “Jiangnan“ featured at the 2022 Zijin Culture and Arts Festival'
              },
              {
                title:'2022年9月30日—10月5日',
                titleEn:'September 30 to October 5, 2022',
                name:'举办第二届“运河情·江南韵”——大运河民族音乐文化传承展示周',
                nameEn:'Held the second “Affection for the Canal: Melodies of Jiangnan“ Grand Canal Chinese Music Culture Heritage Week'
              },
              {
                title:'2022年9月22日',
                titleEn:'September 22, 2022',
                name:'举办第四届大运河文化旅游博览会开幕主题音乐会《大运河》',
                nameEn:'Hosted the opening themed concert “The Grand Canal“ for the Fourth Grand Canal Culture and Tourism Expo'
              },
              {
                title:'2022年8月',
                titleEn:'August 2022',
                name:'委约作品《江河湖海颂》入选文旅部2022—2023年度“时代交响——中国交响音乐作品创作扶持计划”',
                nameEn:'Commissioned work “Ode to Rivers, Lakes, and Seas” selected for the 2022–2023 "Symphony of the Era - China Symphonic Music Composition Support Program" by the Ministry of Culture and Tourism'
              },
              {
                title:'2022年3月',
                titleEn:'March 2022',
                name:'委约作品《江河湖海颂》入选“国家艺术基金2022年度资助项目”',
                nameEn:'Commissioned work “Ode to Rivers, Lakes, and Seas” selected for the National Art Foundation 2022 Annual Subsidized Projects'
              },
              {
                title:'2022年2月',
                titleEn:'February 2022',
                name:'“江河湖海颂”“风雅颂之交响”两场音乐会入选中宣部文艺局、文旅部艺术司、中国音协、上海市委宣传部“时代交响——全国优秀乐团邀请展演”',
                nameEn:'Concerts “Ode to Rivers, Lakes, and Seas” and “The Feng-Ya-Song Symphony” selected for the "Symphony of the Era - Invitational Concerts by National Outstanding Orchestras" organized by the Literature and Art Bureau of the Publicity Department of the CPC Central Committee, the Art Department of the Ministry of Culture and Tourism, China Musicians Association, and the Publicity Department of Shanghai Municipal Party Committee'
              },
              {
                title:'2022年1月',
                titleEn:'January 2022',
                name:'荣获“2019—2021年度江苏省文明单位”称号',
                nameEn:'Awarded the title of "Civilized Unit of Jiangsu Province" for the 2019–2021 period'
              }
            ]
          },
          {
            id:3,
            year:'2021',
            child:[
              {
                title:'2021年12月26日',
                titleEn:'December 26, 2021',
                name:'承办“第二届江苏·苏州民族音乐展演暨2022新年民族音乐会”',
                nameEn:'Hosted “The 2nd Jiangsu-Suzhou Chinese Music Festival” and “2022 New Year Chinese Music Concert”'
              },
              {
                title:'2021年12月24日—26日',
                titleEn:'December 24-26, 2021',
                name:'举办文旅部全国公共文化发展中心、民族民间文艺发展中心首届“运河情 江南韵——大运河民族音乐文化传承展示周”活动',
                nameEn:"Held the first “Affection for the Canal: Melodies of Jiangnan” Grand Canal Chinese Music Culture Heritage Week organized by the National Public Culture Development Center and the Center for Ethnic and Folk Literature and Art Development of the Ministry of Culture and Tourism"
              },
              {
                title:'2021年12月20日',
                titleEn:'December 20, 2021',
                name:'参演江苏省文旅厅、江苏省人民政府外事办公室、悉尼中国文化中心2021年澳大利亚“江苏文化和旅游年”闭幕式暨中澳新年音乐会',
                nameEn:'Performed at the closing ceremony of the 2021 “Year of Jiangsu Culture and Tourism in Australia” and the China-Australia New Year Concert organized by Jiangsu Provincial Department of Culture and Tourism, Jiangsu Provincial Foreign Affairs Office, and the Chinese Cultural Center in Sydney'
              },
              {
                title:'2021年11月25日',
                titleEn:'November 25, 2021',
                name:'荣获“苏州市2018—2020年度文明单位”称号',
                nameEn:'Awarded the title of “Suzhou Civilized Unit” for the 2018-2020 period'
              },
              {
                title:'2021年11月18日',
                titleEn:'November 18, 2021',
                name:'荣获人社部、文旅部“全国文化和旅游系统先进集体”称号',
                nameEn:'Recognized as an “Advanced Group of National Cultural Tourism System” by the Ministry of Human Resources and Social Security and the Ministry of Culture and Tourism'
              },
              {
                title:'2021年10月27日',
                titleEn:'October 27, 2021',
                name:'荣获“江苏省对台交流基地”称号，并承办2021海峡两岸（苏州）青年文化月开幕演出“同唱一首歌 共圆中国梦”两岸青年音乐会',
                nameEn:'Awarded the title of “Jiangsu Provincial Cross-Strait Exchange Base” and hosted “Singing Together for the Chinese Dream” Cross-Strait Youth Concert, the opening performance of the 2021 Cross-Strait (Suzhou) Youth Culture Month'
              },
              {
                title:'2021年10月30日',
                titleEn:'October 30, 2021',
                name:'大型民族管弦乐《江河湖海颂》荣获“2021紫金文化艺术节优秀大型音乐作品奖”',
                nameEn:'Grand-scale Chinese Orchestra “Ode to Rivers, Lakes, and Seas” received the "Outstanding Grand-Scale Music Work Award" at the 2021 Zijin Culture and Arts Festival'
              },
              {
                title:'2021年10月1日',
                titleEn:'October 1, 2021',
                name:'乐团艺术总监兼首席指挥彭家鹏当选第76届国际青年音乐联盟执委',
                nameEn:'Artistic Director and Chief Conductor Pang Kapang elected as Board Member of the 76th Jeunesses Musicales International Annual General Assembly'
              },
              {
                title:'2021年9月27日',
                titleEn:'September 27, 2021',
                name:'中国文联、中国音协“中国江南音乐创作研发基地”落户苏州，由乐团负责基地的创建工作及日常运行',
                nameEn:'The "Jiangnan Music Creation R&D Base" of China Federation of Literature and Art Circles and China Musicians Association established in Suzhou, with the Orchestra in charge of its operations'
              },
              {
                title:'2021年8月3日',
                titleEn:'August 3, 2021',
                name:'苏州民族管弦乐团荣获奥地利音乐剧院“国际交响乐团奖”，乐团艺术总监兼首席指挥彭家鹏荣获奥地利音乐剧院“国际音乐文化成就奖”',
                nameEn:'Suzhou Chinese Orchestra awarded the “International Symphony Orchestra Award” by the Austrian Music Theater; Artistic Director and Chief Conductor Pang Kapang received the Austrian Musical Theater Award for “International Music and Cultural Engagement.”'
              },
              {
                title:'2021年7月',
                titleEn:'July 2021',
                name:'委约作品《丝竹里的江南》入选“国家艺术基金2020年度资助项目”',
                nameEn:'Commissioned work “Jiangnan Within Sizhu” selected for the National Art Foundation 2020 Annual Subsidized Projects'
              },
              {
                title:'2021年5月25日',
                titleEn:'May 25, 2021',
                name:'承办中共苏州市委党史学习教育领导小组办公室、中共苏州市委党校“百年大党•风华正茂——经典音乐中的党史”主题音乐党课',
                nameEn:'Hosted the themed musical lecture “A Century-Old Party in Full Bloom - Party History in Classic Music” organized by the Suzhou Municipal Party History Education Leading Group Office and Suzhou Municipal Party School'
              },
              {
                title:'2021年4月24日',
                titleEn:'April 24, 2021',
                name:'与文旅部全国公共文化发展中心签署战略合作共建协议，就中国民族音乐普及推广、大运河沿线城市民族音乐文化合作交流、民族音乐文创产品开发等方面展开长期合作',
                nameEn:'Signed a strategic cooperation agreement with the Ministry of Culture and Tourism’s National Public Culture Development Center to collaborate on Chinese music promotion, cultural exchanges with cities along the Grand Canal, and the development of creative Chinese music products'
              },
              {
                title:'2021年3月—4月',
                titleEn:'March to April 2021',
                name:'承办中国音协、江苏省委宣传部、江苏省文联“百年回响·中华逐梦”2020中国民族管弦乐、室内乐作品国际征集评选活动，并举办2场获奖作品音乐会',
                nameEn:'Hosted the 2020 Chinese Orchestra and Chamber Music International Composition Contest “Echoes of a Century – Chasing the Chinese Dream” organized by China Musicians Association, Jiangsu Provincial Publicity Department, and Jiangsu Federation of Literature and Art Circles, and presented two concerts featuring award-winning pieces'
              },
              {
                title:'2021年2月',
                titleEn:'February 2021',
                name:'民族交响音乐会“华乐苏韵”入选“江苏省庆祝中国共产党成立100周年舞台艺术精品创作工程”',
                nameEn:'Chinese Symphony Concert “Chinese Music with the Charm of Suzhou” selected for the "Jiangsu Provincial Stage Art Quality Project" celebrating the 100th anniversary of the founding of the Chinese Communist Party'
              },
              {
                title:'2021年1月19日',
                titleEn:'January 19, 2021',
                name:'“丝竹江南”线上中国管弦乐音乐会项目荣获“2020年全省对外宣传工作创新奖”',
                nameEn:'Online Chinese orchestral concert project “Sizhu of Jiangnan” received the “2020 Jiangsu Provincial Award for Innovation in International Publicity Work”'
              },
            ]
          },
          {
            id:4,
            year:'2020',
            child:[
              {
                title:'2020年12月23日—26日',
                titleEn:'December 23-26, 2020',
                name:'承办苏州市文广旅局、苏州市文联“首届江苏•苏州民族音乐展演”',
                nameEn:'Hosted the inaugural “Jiangsu-Suzhou Chinese Music Festival” organized by Suzhou Municipal Bureau of Culture, Radio, Television, and Tourism and the Suzhou Federation of Literature and Art Circles'
              },
              {
                title:'2020年12月15日',
                titleEn:'December 15, 2020',
                name:'发起成立苏州市民族管弦乐艺术团队联合会',
                nameEn:"Initiated the founding of the Federation of Suzhou Chinese Orchestral Art Teams"
              },
              {
                title:'2020年12月',
                titleEn:'December 2020',
                name:'大型民族管弦音乐会“丝竹里的江南”荣获苏州市“文华大奖”',
                nameEn:'Grand-scale Chinese Orchestral Concert “Jiangnan Within Sizhu” awarded the "Wenhua Grand Prize" by Suzhou Municipal Government'
              },
              {
                title:'2020年11月',
                titleEn:'November 2020',
                name:'委约作品《艰难与辉煌》入选文旅部2020—2021年度“时代交响——中国交响音乐作品创作扶持计划”',
                nameEn:'Commissioned work “Hardship and Glory” selected for the 2020-2021 "Symphony of the Era - China Symphonic Music Composition Support Program" by the Ministry of Culture and Tourism'
              },
              {
                title:'2020年11月28日',
                titleEn:'November 28, 2020',
                name:'苏州民族管弦乐团建团三周年“天方夜谭”大型音乐会，国家大剧院',
                nameEn:"The concert “Scheherazade”for Suzhou Chinese Orchestra's third anniversary held at the National Center for the Performing Arts"
              },
              {
                title:'2020年10月30日',
                titleEn:'October 30, 2020',
                name:'“丝竹里的江南”音乐会荣获“2020紫金文化艺术节优秀剧目奖”',
                nameEn:'The concert “Jiangnan Within Sizhu” received the "Outstanding Play Award" at the 2020 Zijin Culture and Arts Festival'
              },
              {
                title:'2020年10月11日',
                titleEn:'October 11, 2020',
                name:'“丝竹里的江南”主题民族管弦音乐会亮相“北京国际音乐节”',
                nameEn:'The themed Chinese orchestral concert “Jiangnan Within Sizhu” featured at the Beijing International Music Festival'
              },
              {
                title:'2020年6月21日',
                titleEn:'June 21, 2020',
                name:'“唱响新时代 逐梦奔小康”音乐会，苏州市四套班子主要领导观看，苏州保利大剧院',
                nameEn:'“Singing for the New Era, Chasing Dreams of Prosperity”: a concert performed at Suzhou Poly Theater and attended by top Suzhou government officials'
              },
              {
                title:'2020年5月',
                titleEn:'May 2020',
                name:'荣获“2019年度全省艺术创作工作先进单位”称号',
                nameEn:'Awarded the title of "2019 Jiangsu Provincial Advanced Unit for Artistic Creations"'
              }
            ]
          },
          {
            id:5,
            year:'2019',
            child:[
              {
                title:'2019年11月26日—12月1日',
                titleEn:'November 26 to December 1, 2019',
                name:'首次韩国巡演——“华乐苏韵”大型音乐会亮相“第36届韩国国际音乐节”，并赴韩国首尔、南园、光州、釜山等地展开巡演',
                nameEn:'First South Korea Tour “Chinese Music with the Charm of Suzhou” featured at the 36th Korean International Music Festival with performances in Seoul, Namwon, Gwangju, and Busan'
              },
              {
                title:'2019年10月26日',
                titleEn:'October 26, 2019',
                name:'乐团首席孙瑶琦荣获中国文联、中国音协“中国音乐金钟奖”',
                nameEn:"Concertmaster Sun Yaoqi awarded the “China Golden Bell Award” by China Federation of Literature and Art Circles and China Musicians Association"
              },
              {
                title:'2019年10月19日',
                titleEn:'October 19, 2019',
                name:'“风雅颂之交响”赵季平作品专场音乐会亮相“2019江苏省紫金文化艺术节”',
                nameEn:'“The Feng-Ya-Song Symphony”, a special concert of Zhao Japing’s Chinese orchestral works performed at the 2019 Jiangsu Zijin Culture and Arts Festival'
              },
              {
                title:'2019年10月31日—11月4日',
                titleEn:'October 31 to November 4, 2019',
                name:'承办第74届国际青年音乐联盟代表大会',
                nameEn:'Hosted the 74th JMI Annual General Assembly'
              },
              {
                title:'2019年9月12日—21日',
                titleEn:'September 12-21, 2019',
                name:'首次日本巡演——赴日本开展中日文化交流访演活动，在日本广岛、名古屋、金泽、东京等地举办4场“华乐苏韵”大型音乐会',
                nameEn:"First Japan tour “Chinese Music with the Charm of Suzhou”: 4 grand concerts held in Hiroshima, Nagoya, Kanazawa, and Tokyo for Sino-Japanese cultural exchanges"
              },
              {
                title:'2019年9月',
                titleEn:'September 2019',
                name:'委约作品《风雅颂之交响》《丝竹的交响》《烟雨枫桥》入选文旅部2019年“时代交响——中国交响音乐作品创作扶持计划”',
                nameEn:'Commissioned works “The Feng-Ya-Song Symphony,” “Symphony of Sizhu,” and “Misty Rain on Maple Bridge” selected into “Symphony of the Era”, a support program by the Ministry of Culture and Tourism for the creation of Chinese symphonic music'
              },
              {
                title:'2019年9月26日',
                titleEn:'September 26, 2019',
                name:'苏州民族管弦乐团音乐厅首演暨建团两周年创作发展座谈会',
                nameEn:'Inaugural performance at the Suzhou Chinese Orchestra Concert Hall and symposium on the orchestra’s second anniversary and development'
              },
              {
                title:'2019年9月25日',
                titleEn:'September 25, 2019',
                name:'苏州民族管弦乐团团部及音乐厅正式落成',
                nameEn:'Suzhou Chinese Orchestra Headquarters and Concert Hall officially completed'
              },
              {
                title:'2019年8月28日',
                titleEn:'August 28, 2019',
                name:'“华乐苏韵”大型民族管弦乐原创作品音乐会荣获江苏省文华大奖',
                nameEn:'The original Chinese orchestral concert “Chinese Music with the Charm of Suzhou” received the Jiangsu Provincial Wenhua Award'
              },
              {
                title:'2019年8月20日',
                titleEn:'August 20, 2019',
                name:'“华乐苏韵”欧洲巡演项目荣获文旅部2019年“欢乐春节”引导奖励资金评选一等奖',
                nameEn:'The European tour “Chinese Music with the Charm of Suzhou” received first prize in “Happy Chinese New Year Guidance Awards” from the Ministry of Culture and Tourism'
              },
              {
                title:'2019年6月13日',
                titleEn:'June 13, 2019',
                name:'“丝竹里的江南”主题音乐会，国家大剧院',
                nameEn:'The themed concert “Jiangnan Within Sizhu” put on at the National Center for the Performing Arts'
              },
              {
                title:'2019年5月1日',
                titleEn:'May 1, 2019',
                name:'苏州民族管弦乐团与台北市立国乐团签署友好乐团备忘录，开展深度交流合作，台北',
                nameEn:'Suzhou Chinese Orchestra signs a memorandum of friendship with Taipei Chinese Orchestra to promote in-depth exchanges and cooperation in Taipei'
              },
              {
                title:'2019年4月',
                titleEn:'April 2019',
                name:'委约作品《干将•莫邪幻想曲》入选文旅部2018年“时代交响——中国交响音乐作品创作扶持计划”',
                nameEn:'Commissioned work “Fantasia of Gan Jiang Mo Ye” selected for the 2018 "Symphony of the Era - China Symphonic Music Composition Support Program" by the Ministry of Culture and Tourism'
              },
              {
                title:'2019年2月',
                titleEn:'February 2019',
                name:'荣获“2018年度全省艺术创作工作先进单位”称号',
                nameEn:'Awarded the title of "2018 Jiangsu Provincial Advanced Unit for Artistic Creations"'
              },
              {
                title:'2019年1月15日—2月10日',
                titleEn:'January 15 to February 10, 2019',
                name:'首次欧洲巡演——赴德国、比利时、意大利、列支敦士登、瑞士、奥地利、匈牙利、波兰、俄罗斯等9国13城市举办13场“华乐苏韵”大型民族管弦乐音乐会',
                nameEn:'First European tour: 13 “Chinese Music with the Charm of Suzhou” grand Chinese orchestral concerts across 13 cities in 9 countries, including Germany, Belgium, Italy, Liechtenstein, Switzerland, Austria, Hungary, Poland, and Russia'
              },
              {
                title:'2019年1月14日',
                titleEn:'January 14, 2019',
                name:'参演庆祝新中国成立70周年国家级大型演出“致新时代——大型原创交响音乐会”，国家大剧院',
                nameEn:"Performance at the National Center for the Performing Arts in “A Tribute to the New Era”, a national grand-scale concert celebrating the 70th anniversary of the founding of the People's Republic of China"
              }
            ]
          },
          {
            id:6,
            year:'2018',
            child:[
              {
                title:'2018年12月',
                titleEn:'December 2018',
                name:'民族管弦乐“华乐苏韵”欧洲巡演入选“国家艺术基金2019年度资助项目”',
                nameEn:'The European tour“Chinese Music with the Charm of Suzhou” selected for the National Art Foundation 2019 Annual Subsidized Projects'
              },
              {
                title:'2018年10月18日',
                titleEn:'October 18, 2018',
                name:'“丝竹的交响”原创作品音乐会亮相“2018江苏省紫金文化艺术节”，江苏大剧院',
                nameEn:"The Original works concert “Symphony of Sizhu“ at Jiangsu Grand Theater for the 2018 Jiangsu Zijin Culture and Arts Festival"
              },
              {
                title:'2018年9月29日',
                titleEn:'September 29, 2018',
                name:'参演江苏省委宣传部“潮涌新时代”——江苏省庆祝改革开放40周年文艺演出暨紫金文化艺术节开幕式，江苏大剧院',
                nameEn:'Performed at the opening ceremony of the Zijin Culture and Arts Festival at Jiangsu Grand Theater for the“Waves of the New Era“ , a cultural show organized by the Publicity Department of the Jiangsu Provincial Party Committee celebrating the 40th anniversary of reform and opening-up'
              },
              {
                title:'2018年9月11日—12日',
                titleEn:'September 11-12, 2018',
                name:'“华乐苏韵”音乐会亮相文旅部“第五届丝绸之路国际艺术节”，荣获“丝路文化贡献奖”，西安音乐厅',
                nameEn:"“Chinese Music with the Charm of Suzhou” , a concert at Xi'an Concert Hall for the 5th Silk Road International Art Festival organized by the Ministry of Culture and Tourism; received the “Silk Road Cultural Contribution Award”"
              },
              {
                title:'2018年7月27日—28日',
                titleEn:'July 27-28, 2018',
                name:'首次海外演出——纪念中美建交40周年、苏州市与美国波特兰市结为友好城市30周年活动“华乐苏韵”音乐会，美国波特兰',
                nameEn:"Overseas debut performance: “Chinese Music with the Charm of Suzhou“, a concert in Portland, USA, marking the 40th anniversary of China-U.S. diplomatic relations and the 30th anniversary of the sister city relationship between Suzhou and Portland"
              },
              {
                title:'2018年6月11日',
                titleEn:'June 11, 2018',
                name:'“高雅艺术进社区”活动正式启动',
                nameEn:'Official Launch of the “Bringing High Art to Communities” Program'
              },
              {
                title:'2018年6月1日',
                titleEn:'June 1, 2018',
                name:'苏州民族管弦乐团工会正式成立',
                nameEn:'Official Establishment of Suzhou Chinese Orchestra’s Union'
              },
              {
                title:'2018年5月21日',
                titleEn:'May 21, 2018',
                name:'首场北京演出——“长歌行”民族管弦乐作品音乐会亮相“2018北京现代音乐节”',
                nameEn:'Debut Performance in Beijing: “The Long Ballad”, a Chinese Orchestral Concert at the 2018 Beijing Modern Music Festival'
              },
              {
                title:'2018年4月20日',
                titleEn:'April 20, 2018',
                name:'首场上海演出——“华乐苏韵”大型民族管弦乐音乐会，上海交响乐团音乐厅',
                nameEn:'Debut Performance in Shanghai: “Chinese Music with the Charm of Suzhou” Grand Chinese Orchestra Concert at Shanghai Symphony Hall'
              },
              {
                title:'2018年3月28日',
                titleEn:'March 28, 2018',
                name:'“高雅艺术进校园”活动正式启动',
                nameEn:'Official Launch of the “High Art Entering School Campuses” Program'
              },
              {
                title:'2018年3月1日',
                titleEn:'March 1, 2018',
                name:'苏州民族管弦乐团党支部成立',
                nameEn:'Establishment of Suzhou Chinese Orchestra’s Party Branch'
              },
              {
                title:'2018年1月28日',
                titleEn:'January 28, 2018',
                name:'首场南京演出——“华乐苏韵·时代交响”苏州民族管弦乐团赴省汇报音乐会，江苏大剧院',
                nameEn:'First Performance in Nanjing: “Chinese Music with the Charm of Suzhou - Symphony of the Era,” a report concert at Jiangsu Grand Theater'
              },
            ]
          },
          {
            id:7,
            year:'2017',
            child:[
              {
                title:'2017年12月25日',
                titleEn:'December 25, 2017',
                name:'苏州民族管弦乐团建团首演座谈研讨会',
                nameEn:'Symposium on Suzhou Chinese Orchestra’s Founding and Inaugural Concert'
              },
              {
                title:'2017年12月24日—25日',
                titleEn:'December 24-25, 2017',
                name:'苏州民族管弦乐团建团揭牌仪式暨“丝竹江南·丝竹交响”建团首场音乐会',
                nameEn:"Founding Ceremony of Suzhou Chinese Orchestra and Inaugural Concert “Sizhu of Jiangnan - Symphony of Sizhu”"
              }        
            ]
          },
        ]
    };
  },
  computed: {},
  mounted(){
    this.incident()
  },
  methods: {
    incident(val){
        if(val){
            this.listIndex = val.id
        } 
        this.list = this.journalListL[this.listIndex].child
      },
    chronicle(){
       this.$ajax.get("/cms/api/bigevent", {}).then((res) => {
        if (res.code == "200") {
          this.bigevent = res.data
        }
      });
    }
  },
};
</script>

<style lang="scss" scoped>
  @keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(90deg);
  }
}
 
.rotating-element {
  animation: rotate 1s linear 1 forwards;
}
.record {
  // padding: 42px 2px 0 14px;
  position: relative;
  padding-bottom:20px;
  .record-li {
    padding-top: 42px;
    position: relative;
    z-index: 11;
    .record-li-left {
      margin-left: 15px;
      .record-left-img1 {
        display: block;
        width: 65px;
        height: 29px;
        margin-bottom:59px;
        background-size: 100% 100%;
        position: relative;
        .date-sj{
            display: inline-block;
            width: 0;
            height: 0;
            border-top: 6px solid transparent;
            border-bottom: 6px solid transparent;
            border-left: 8px solid #634636;
            position: absolute;
            top: 11px;
            left: 74px;
        }
      }
      .record-left-time {
        margin-top: 10px;
      }
      .record-left-img2{
        width: 92px;
        height: 164px;
        margin-top:24px;
        position: relative;
        left:-16px;
      }
    }
    .record-li-right{
      //  width: 196px;
       margin-left: 40px;
       margin-right:16px;
       text-align: right;
      .record-right-hz{
        position: relative;
        width: 100%;
        .record-right-img1{
          width: 100%;
          height: 131px;
          // background-image: url('../../../assets/rec/rec-5.png');
          // background-size: 100%;
          z-index: 2;
           position: relative;
           border-radius: 25px 0 25px 0;
        }
         .record-right-img2{
           width: 48px;
           height: 50px;
           position: absolute;
           top:-25px;
           right:-15px;
           z-index: 1;
        }
        .record-right-img3{
          width: 41px;
          height: 49px;
           position: absolute;
           bottom:0;
           left:0;
           z-index: 3;
        }
      }
     .record-right-img4{
       width: 127px;
       height: 19px;
       margin:6px 35px 10px 0;
     }
    }
  }
}
.record-bac {
  position: absolute;
  top: 0;
  left: 81px;
  background-image: url("../../../assets/rec/rec-4.png");
  background-size: 100%;
  width: 20px;
  height: 100%;
}
.item-right-top{
      position: relative;
      .bg-right {
          width: 39px;
          height: 39px;
          background-image: url("../../../assets/rec/rec-3.png");
          background-size: 100% 100%;
          position: absolute;
          top: -20px;
          right: -15px;
          z-index: 0;
          }
      .bg-img {
          width: 196px;
          height: 130px;
          // background-size: 100% 100%;
          position: relative;
          z-index: 1;
          margin-bottom: 16px;
      }
      .en-img {
          display: inline-block;
          width: 127px;
          height: 21px;
          background-image: url("../../../assets/rec/rec-9.png");
          background-size: 100% 100%;
          position: absolute;
          top: 120px;
          right: 20px;
      }
  }
  .name {
    font-size: 15px;
    font-weight: 400;
    color: #634636;
    letter-spacing: 0.32px;
    margin-top: 6px;
    // position: relative;
    //   &::after {
    //       content: "";
    //       display: inline-block;
    //       width: 0;
    //       height: 0;
    //       border-top: 5px solid transparent;
    //       border-bottom:5px solid transparent;
    //       border-left: 8px solid #634636;
    //       position: absolute;
    //       top: 20px;
    //       left: -25px;
    //   }
  }
  .text {
    font-size: 12px;
    font-weight: 300;
    color: #2c3054;
    line-height: 28px;
    margin-top: 16px;
    text-align: left;
    letter-spacing: 0.09px;
  }
</style>


var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[_c('Nav'),_c('div',{staticClass:"mt-50"},[_c('img',{staticClass:"top-img",attrs:{"src":require(`@/assets/banner/banner-06${_vm.$i18n.locale == 'zh'?'z':'e'}.png`),"alt":""}}),_c('div',{staticClass:"record"},[_c('div',{staticClass:"record-bac"}),_c('div',{staticClass:"record-li flex between"},[_c('div',{staticClass:"record-li-left"},_vm._l((_vm.journalListL),function(item){return _c('div',{key:item.id,staticClass:"record-left-img1",style:({
                      backgroundImage:
                        'url(' +
                        require('@/assets/rec/dsj-' +
                        item.year +
                        '.png') +
                        ')',
                  }),on:{"click":function($event){return _vm.incident(item)}}},[_c('div',{staticClass:"date-sj",class:item.id == _vm.listIndex?'rotating-element' : ''})])}),0),_c('div',{staticClass:"record-li-right"},_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"mb-26"},[(item.url)?_c('div',{staticClass:"item-right-top"},[_c('div',{staticClass:"bg-right"}),_c('img',{staticClass:"bg-img",attrs:{"src":require('@/assets/rec/' +item.url ),"alt":""}}),_c('div',{staticClass:"en-img"})]):_vm._e(),(_vm.$i18n.locale == 'zh')?_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.title))]):_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.titleEn))]),(_vm.$i18n.locale == 'zh')?_c('div',{staticClass:"text"},[_vm._v(_vm._s(item.name))]):_c('div',{staticClass:"text"},[_vm._v(_vm._s(item.nameEn))])])}),0)])])]),_c('Footer')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }